export default function Hero() {
  return (
    <div>
      <section className="hero header_hero align-items-center">
        <div className="container">
          <div className="hero-content custom-row mt-25 align-items-center ">
            <div className="intro col-lg-6">
              <h2>Hello I'm</h2>
            </div>
          </div>
        </div>
        <div className="header_hero_shape d-none d-lg-block"></div>
      </section>
    </div>
  );
}
